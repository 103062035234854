<template>
  <component
    :is="typeToPageMap[page.meta.type]"
    v-if="Object.keys(typeToPageMap).includes(page.meta.type)"
    :page="page"
  ></component>
  <div v-else>
    <div
      v-for="item in pageContent"
      :id="item.id"
      :key="item.id"
      :hidden="!item.value.meta.visible"
    >
      <PageIntro
        v-if="item.type === 'intro'"
        :type="item.value.type"
        :label-icon="item.value.label_icon"
        :label-text="item.value.label_text"
        :top="item.value.top"
        :title="item.value.title"
        :text="item.value.text"
        :wide-content="item.value.wide_content"
        :buttons="item.value.buttons"
        :image="item.value.image?.image"
        :retina-image="item.value.image?.retina_image"
        :background-gradient="item.value.background_gradient"
        :usps="item.value.usps"
        :class="item.value.meta.root_element_classes"
      ></PageIntro>
      <PageIntroWithStats
        v-if="item.type === 'intro_with_stats'"
        :title="item.value.title"
        :text="item.value.text"
        :stats="item.value.stats"
        :class="item.value.meta.root_element_classes"
      ></PageIntroWithStats>
      <PageHorizontalContentImage
        v-else-if="item.type === 'horizontal_content_image'"
        :title="item.value.title"
        :text="item.value.text"
        :reversed="item.value.direction === 'image_left_content_right'"
        :retina-image="item.value.image.width_896"
        :image="item.value.image.width_448"
        :template="item.value.template"
        :template-image-padding="item.value.template_image_padding"
        :class="item.value.meta.root_element_classes"
      ></PageHorizontalContentImage>
      <PageVerticalContentImage
        v-else-if="item.type === 'vertical_content_image'"
        :background="item.value.background"
        :title="item.value.title"
        :title-wide="item.value.title_wide"
        :text="item.value.text"
        :buttons="item.value.buttons"
        :retina-image="item.value.image.retina_image"
        :image="item.value.image.image"
        :image-size="item.value.image_size"
        :class="item.value.meta.root_element_classes"
      ></PageVerticalContentImage>
      <PageLogos
        v-else-if="item.type === 'logos'"
        :title="item.value.title"
        :logos="item.value.logos"
        :class="item.value.meta.root_element_classes"
      ></PageLogos>
      <div
        v-else-if="item.type === 'templates'"
        class="saas-section"
        :class="item.value.meta.root_element_classes"
      >
        <div v-if="item.value.title" class="saas-section__title">
          <h2 class="saas-striped-title">{{ item.value.title }}</h2>
        </div>
        <div v-if="item.value.description" class="saas-section__description">
          {{ item.value.description }}
        </div>
        <PageTemplates :templates="item.value.templates"></PageTemplates>
        <div class="saas-section__actions margin-bottom-0">
          <CmsLink
            v-for="button in item.value.buttons"
            :key="button.id"
            :url-or-path="button.url_or_path"
            :class="`button button--${button.type} button--large`"
          >
            {{ button.text }}
          </CmsLink>
        </div>
      </div>
      <div
        v-else-if="item.type === 'messages'"
        class="saas-section saas-section--dark-to-light"
        :class="item.value.meta.root_element_classes"
      >
        <div
          v-if="item.value.title"
          class="saas-section__title saas-section__title--wide"
        >
          <h2 class="saas-striped-title">{{ item.value.title }}</h2>
        </div>
        <PageHorizontalMessages
          :message-rows="item.value.messages"
        ></PageHorizontalMessages>
      </div>
      <div
        v-else-if="item.type === 'icon_grid'"
        class="saas-section"
        :class="[
          {
            'saas-section--gray': item.value.background === 'gray',
            'saas-section--light-to-dark':
              item.value.background === 'light_to_dark',
            'saas-section--dark-to-light':
              item.value.background === 'dark_to_light',
          },
          item.value.meta.root_element_classes,
        ]"
      >
        <div
          v-if="item.value.title"
          class="saas-section__title"
          :class="{ 'saas-section__title--wide': item.value.wide }"
        >
          <h2 class="saas-striped-title">{{ item.value.title }}</h2>
        </div>
        <div v-if="item.value.text" class="saas-section__description">
          {{ item.value.text }}
        </div>
        <PageIconContainer
          v-if="item.value.contained"
          :icons="item.value.usps"
        ></PageIconContainer>
        <PageIconGrid v-else :icons="item.value.usps"></PageIconGrid>
      </div>
      <div
        v-else-if="item.type === 'content_blocks'"
        class="saas-section"
        :class="[
          {
            'saas-section--light-to-dark':
              item.value.background_gradient == 'light-to-dark',
            'saas-section--dark-to-light':
              item.value.background_gradient == 'dark-to-light',
          },
          item.value.meta.root_element_classes,
        ]"
      >
        <div
          v-if="item.value.title"
          class="saas-section__title saas-section__title--wide"
        >
          <h2 class="saas-striped-title">{{ item.value.title }}</h2>
        </div>
        <PageContentBlocks :blocks="item.value.blocks"></PageContentBlocks>
      </div>
      <div
        v-else-if="item.type === 'timeline'"
        class="saas-section"
        :class="item.value.meta.root_element_classes"
      >
        <div v-if="item.value.title" class="saas-section__title">
          <h2 class="saas-striped-title">{{ item.value.title }}</h2>
        </div>
        <PageTimeline :items="item.value.items"></PageTimeline>
      </div>
      <PagePhotos
        v-else-if="item.type === 'photos'"
        :title="item.value.title"
        :text="item.value.text"
        :photo1="item.value.photo_1"
        :photo2="item.value.photo_2"
        :photo3="item.value.photo_3"
        :photo4="item.value.photo_4"
        :class="item.value.meta.root_element_classes"
      ></PagePhotos>
      <div
        v-else-if="item.type === 'quotes'"
        class="saas-section"
        :class="item.value.meta.root_element_classes"
      >
        <div class="saas-section__title">
          <h2 class="saas-striped-title">
            {{ item.value.title }}
          </h2>
        </div>
        <PageQuotes :quotes="item.value.quotes"></PageQuotes>
      </div>
      <div
        v-else-if="item.type === 'boxes'"
        class="saas-section"
        :class="[
          {
            'saas-section--light-to-dark':
              item.value.background_gradient == 'light_to_dark',
            'saas-section--dark-to-light':
              item.value.background_gradient == 'dark_to_light',
          },
          item.value.meta.root_element_classes,
        ]"
      >
        <div v-if="item.value.title" class="saas-section__title">
          <h2 class="saas-striped-title">
            {{ item.value.title }}
          </h2>
        </div>
        <div v-if="item.value.text" class="saas-section__description">
          {{ item.value.text }}
        </div>
        <div
          v-for="(row, index) in item.value.rows"
          :key="row.id"
          class="home-v2-boxes"
          :class="{
            'home-v2-boxes--with-title': item.value.title && index === 0,
          }"
        >
          <PageBox v-for="box in row.boxes" :key="box.id" :box="box"></PageBox>
        </div>
        <div v-if="item.value.buttons" class="saas-section__actions">
          <CmsLink
            v-for="button in item.value.buttons"
            :key="button.id"
            :url-or-path="button.url_or_path"
            :class="`button button--${button.type} button--large`"
          >
            {{ button.text }}
          </CmsLink>
        </div>
      </div>
      <PageIntegration
        v-else-if="item.type === 'integration'"
        :integration="item.value"
        :class="item.value.meta.root_element_classes"
      ></PageIntegration>
      <PageHorizontalLogos
        v-else-if="item.type === 'horizontal_logos'"
        :text="item.value.text"
        :logos="item.value.logos"
        :class="item.value.meta.root_element_classes"
      ></PageHorizontalLogos>
      <PageBadges
        v-else-if="item.type === 'badges'"
        :badges="item.value.badges"
        :class="item.value.meta.root_element_classes"
      ></PageBadges>
      <PageSlider
        v-else-if="item.type === 'slider'"
        :background="item.value.background"
        :title="item.value.title"
        :description="item.value.description"
        :items="item.value.items"
        :class="item.value.meta.root_element_classes"
      ></PageSlider>
      <PageComparison
        v-else-if="item.type === 'comparison'"
        :title="item.value.title"
        :description="item.value.description"
        :use-cases="item.value.use_cases"
        :competitors="item.value.competitors"
        :class="item.value.meta.root_element_classes"
      ></PageComparison>
      <PageCommunity
        v-else-if="item.type === 'community'"
        :title="item.value.title"
        :text="item.value.text"
        :background="item.value.background"
        :buttons="item.value.buttons"
        :class="item.value.meta.root_element_classes"
      ></PageCommunity>
      <PageHomeIntro
        v-else-if="item.type === 'home_intro'"
        :title="item.value.title"
        :description="item.value.description"
        :buttons="item.value.buttons"
        :image="item.value.image"
        :latest-release-blog-post="item.value.latest_release_blog_post"
        :class="item.value.meta.root_element_classes"
      ></PageHomeIntro>
    </div>
    <FooterJoin
      :hide="page.hide_footer"
      :title="page.footer_title"
      :text="page.footer_text"
      :buttons="page.footer_buttons"
    ></FooterJoin>
  </div>
</template>

<script>
import { getBaserowDefaultOpenGraphImage } from '@saas/utils'
import cmsPage from '@saas/mixins/cmsPage'
import roadmap from '@saas/pages/roadmap'
import homepage from '@saas/pages/homepage'
import PageIntro from '@saas/components/page/PageIntro'
import PageIntroWithStats from '@saas/components/page/PageIntroWithStats'
import PageHorizontalContentImage from '@saas/components/page/PageHorizontalContentImage'
import PageVerticalContentImage from '@saas/components/page/PageVerticalContentImage'
import PageLogos from '@saas/components/page/PageLogos'
import PageTemplates from '@saas/components/page/PageTemplates'
import PageHorizontalMessages from '@saas/components/page/PageHorizontalMessages'
import PageIconGrid from '@saas/components/page/PageIconGrid'
import PageIconContainer from '@saas/components/page/PageIconContainer'
import PageContentBlocks from '@saas/components/page/PageContentBlocks'
import PageTimeline from '@saas/components/page/PageTimeline'
import PagePhotos from '@saas/components/page/PagePhotos'
import PageQuotes from '@saas/components/page/PageQuotes'
import PageBox from '@saas/components/page/PageBox'
import PageIntegration from '@saas/components/page/PageIntegration'
import PageHomeIntro from '@saas/components/page/PageHomeIntro'
import FooterJoin from '@saas/components/footer/FooterJoin'
import PageHorizontalLogos from '@saas/components/page/PageHorizontalLogos'
import PageBadges from '@saas/components/page/PageBadges'
import PageSlider from '@saas/components/page/PageSlider'
import PageComparison from '@saas/components/page/PageComparison'
import PageCommunity from '@saas/components/page/PageCommunity'
import CmsLink from '@saas/components/cms/CmsLink'

export default {
  components: {
    PageCommunity,
    PageSlider,
    PageBadges,
    PageHorizontalLogos,
    PageIntro,
    PageIntroWithStats,
    PageLogos,
    PageHorizontalContentImage,
    PageVerticalContentImage,
    PageTemplates,
    PageHorizontalMessages,
    PageIconGrid,
    PageIconContainer,
    PageContentBlocks,
    PageTimeline,
    PagePhotos,
    PageQuotes,
    PageBox,
    PageIntegration,
    PageComparison,
    PageHomeIntro,
    CmsLink,
    FooterJoin,
  },
  mixins: [cmsPage],
  layout: 'saas',
  async asyncData(context) {
    const { app, error } = context
    try {
      const page = await cmsPage.asyncData(context)
      if (page.page.content) {
        const topBars = page.page.content
          .filter((block) => block.type === 'top_bars')
          .flatMap((block) => block.value.top_bars)
        await app.store.dispatch('header/setTopBars', topBars)
      }
      return page
    } catch (e) {
      return error({
        statusCode: 400,
        message: 'Something went wrong.',
      })
    }
  },
  data() {
    return {
      typeToPageMap: {
        'saas_cms.RoadmapPage': roadmap,
        'saas_cms.HomePage': homepage,
      },
    }
  },
  head() {
    const head = cmsPage.head.call(this)
    // If it's the homepage, we want to add some additional metadata.
    if (this.$route.fullPath === '/') {
      Object.assign(head, {
        meta: [
          {
            name: 'keywords',
            content:
              'database, online database, open source, web application, database api, database application, spreadsheet application, data collaboration, data platform, no-code, airtable, airtable alternative',
          },
          {
            property: 'og:locale',
            content: 'en_us',
          },
          {
            property: 'og:site_name',
            content: 'Baserow',
          },
          {
            property: 'og:title',
            content: 'Baserow - Open source no-code database',
          },
          {
            property: 'og:url',
            content: `${this.$config.PUBLIC_WEB_FRONTEND_URL}/`,
          },
          {
            property: 'og:type',
            content: 'website',
          },
          {
            property: 'og:image',
            content: getBaserowDefaultOpenGraphImage(this.$config),
          },
          {
            itemprop: 'name',
            content: 'Baserow - Get the most out of your data.',
          },
          {
            itemprop: 'headline',
            content: 'Baserow - Open source no-code database',
          },
          {
            itemprop: 'author',
            content: 'Baserow',
          },
          ...head.meta,
        ],
      })
    }
    return head
  },
  computed: {
    pageContent() {
      return this.page.content.filter((block) => block.type !== 'top_bars')
    },
  },
  async beforeDestroy() {
    await this.$store.dispatch('header/setTopBars', [])
  },
}
</script>
