<template>
  <div
    class="saas-section"
    :class="{
      'saas-section--gray': background === 'gray',
      'saas-section--light-to-dark': background === 'light_to_dark',
      'saas-section--dark-to-light': background === 'dark_to_light',
    }"
  >
    <div v-if="title" class="saas-section__title saas-section__title--wide">
      <h2 class="saas-striped-title">
        {{ title }}
      </h2>
    </div>
    <div v-if="description" v class="saas-section__description">
      {{ description }}
    </div>
    <div class="home-v2-views">
      <SaaSSegmentControl
        class="home-v2-views__segment-control"
        :segments="segments"
        :selected-segment-index="selectedSegmentIndex"
        @select-segment="handleSelectedSegment"
      ></SaaSSegmentControl>
      <div class="home-v2-views__screenshots-wrapper">
        <div class="home-v2-views__screenshots">
          <div
            class="home-v2-views__screenshots-carousel"
            :style="{ transform: `translateX(-${selectedSegmentIndex}00%)` }"
          >
            <div
              v-for="segment in segments"
              :key="segment.name"
              class="home-v2-views__screenshot"
            >
              <img
                :src="segment.image"
                :alt="segment.name"
                :srcset="`${segment.retinaImage} 2x`"
                class="home-v2-views__screenshot-image"
                loading="lazy"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SaaSSegmentControl from '@saas/components/SaaSSegmentControl'

export default {
  name: 'PageSlider',
  components: { SaaSSegmentControl },
  props: {
    background: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: false,
      default: '',
    },
    description: {
      type: String,
      required: false,
      default: '',
    },
    items: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data() {
    return {
      selectedSegmentIndex: 0,
    }
  },
  computed: {
    segments() {
      return this.items.map((view) => {
        return {
          name: view.name,
          icon: view.icon_class,
          activeIconColor: {
            blue: 'color-primary',
            red: 'color-error',
            green: 'color-success',
            yellow: 'color-warning',
          }[view.color],
          image: view.screenshot.image.src,
          retinaImage: view.retina_screenshot.image.src,
        }
      })
    },
  },
  mounted() {
    this.selectNextViewInterval = setInterval(() => {
      const beforeIndex = this.selectedSegmentIndex
      const afterIndex =
        this.selectedSegmentIndex >= this.segments.length - 1
          ? 0
          : this.selectedSegmentIndex + 1

      if (beforeIndex !== afterIndex) this.selectedSegmentIndex = afterIndex
    }, 5000)
  },
  methods: {
    handleSelectedSegment(index) {
      this.selectedSegmentIndex = index
      clearInterval(this.selectNextViewInterval)
    },
  },
}
</script>
